<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>

    <b-overlay
      :show="showOver"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>
      <b-row class="match-height">

        <b-col md="12">
          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="font-weight-bolder">
                รหัสพัสดุ NONAME
              </h3>

              <div class="d-lg-flex justify-content-between align-items-center px-2">
                <div class="d-flex align-items-center">
                  <div class="anan-input__inner anan-input__inner--normal">
                    <input
                      v-model="search_val"
                      type="text"
                      :placeholder="this.$t('search')+'รหัสพัสดุ'"
                      class="anan-input__input"
                      @input="FilterItem(search_val)"
                    >
                    <div class="anan-input__suffix">
                      <i class="anan-input__clear-btn anan-icon" />
                      <i class="anan-input__suffix-icon anan-icon">
                        <i class="fal fa-search" />
                      </i>
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <v-select
                    v-model="selectCustomer"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="username"
                    :options="option"
                    class="ml-50"
                    :disabled="!items.some(item => item.select == true)"
                    style="width:300px;;"
                  />
                  <b-button
                    variant="primary"
                    class="ml-50 w-50 text-nowarp"
                    :disabled="!items.some(item => item.select == true) || !selectCustomer"
                    @click="updatePost()"
                  >
                    บันทึกรหัสลูกค้า
                  </b-button>
                </div>
              </div>
            </div>
            <div class="table-container">
              <table id="customers">
                <thead>
                  <tr>
                    <th scope="col">
                      <b-form-checkbox
                        v-model="selectAll"
                        @change="isIndeterminate()"
                      />
                      <!-- :indeterminate="isIndeterminate" -->
                    </th>
                    <th scope="col" />
                    <th scope="col">
                      {{ $t('sequence') }}
                    </th>
                    <th scope="col">
                      {{ $t('parcelCode') }}
                    </th>
                    <th scope="col">
                      {{ $t('orderList') }}
                    </th>
                    <!-- <th scope="col">
                      {{ $t('memberCode') }}
                    </th> -->
                    <th scope="col">
                      {{ $t('quantity') }}
                    </th>
                    <th scope="col">
                      {{ $t('weight') }}
                    </th>
                    <th scope="col">
                      {{ $t('width') }}
                    </th>
                    <th scope="col">
                      {{ $t('height') }}
                    </th>
                    <th scope="col">
                      {{ $t('length') }}
                    </th>
                    <th scope="col">
                      {{ $t('cubic') }}
                    </th>
                    <th scope="col">
                      {{ $t('type') }}
                    </th>
                    <th scope="col">
                      {{ $t('arrivedThailand') }}
                    </th>
                    <th scope="col">
                      {{ $t('checkOrder') }}
                    </th>
                    <th scope="col">
                      {{ $t('repackage') }}
                    </th>
                    <th scope="col">
                      {{ $t('transport') }}
                    </th>
                    <th scope="col">
                      {{ $t('other') }}
                    </th>
                    <th scope="col">
                      {{ $t('cratePricing') }}
                    </th>
                    <th scope="col">
                      {{ $t('pricePerKilo') }}
                    </th>
                    <th scope="col">
                      {{ $t('pricePerCubic') }}
                    </th>
                  </tr>
                </thead>
                <thead class="text-center">
                  <tr>
                    <th
                      colspan="5"
                      style="background: #fff; color: black;"
                    >
                      <b>{{ $t('total') }}</b>
                    </th>
                    <th
                      class="text-center"
                      scope="col"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ Commas(countproducts()) }}</b>
                    </th>
                    <th
                      scope="col"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ Commas(countweight()) }}</b>
                    </th>
                    <th
                      scope="col"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ Commas(countwidth()) }}</b>
                    </th>
                    <th
                      scope="col"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ Commas(countheight()) }}</b>
                    </th>
                    <th
                      scope="col"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ Commas(countproductLong()) }}</b>
                    </th>
                    <th
                      scope="col"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ CommasCue(countquantity()) }}</b>
                    </th>
                    <th
                      scope="col"
                      colspan="7"
                      style="background: #fff"
                    />

                    <th
                      scope="col"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ Commas(conutkilo()) }}</b>
                    </th>
                    <th
                      scope="col"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ Commas(conutcue()) }}</b>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item,index) in items"
                    :key="index"
                    :class="`ctx-${checkedcolor(item)}`"
                  >
                    <!-- {{ items }} -->
                    <td
                      scope="col"
                      class="text-center"
                    >
                      <b-form-checkbox
                        v-model="item.select"
                        @input="checkboxselect(item.select,item)"
                      />
                      {{ selectItem[index] }}
                    </td>
                    <td scope="col">
                      <feather-icon
                        icon="Edit3Icon"
                        class="mr-50 text-primary cursor-pointer"
                        @click="EditType(item)"
                      />
                    </td>
                    <td scope="col">
                      <i
                        v-if="item.transportType === 1"
                        class="fas fa-truck-moving"
                      />
                      <i
                        v-if="item.transportType === 2"
                        class="fas fa-ship"
                      />
                      <router-link :to="{name : 'admin-warehouse-lot'}">
                        {{ item.lot_name ? item.lot_name: '' }}
                      </router-link>{{ item.lot_order ? `/${item.lot_order}`: '' }}
                    </td>
                    <td
                      scope="col"
                      class="text-left"
                    >
                      <router-link :to="{name : 'admin-tracking-edit', params: { id: item._id }}">
                        {{ item.postnumber ? item.postnumber: '-' }}
                      </router-link>
                    </td>
                    <td
                      scope="col"
                      class="text-left"
                    >
                      <router-link
                        v-if="item.order_id"
                        :to="{name : 'admin-order-detail', params: { id: item.order_id }}"
                      >
                        {{ item.order_text }}
                      </router-link>
                      <span v-else>-</span>
                    </td>
                    <!-- <td scope="col">
                      {{ getoption.username }}
                    </td> -->
                    <td scope="col">
                      {{ Commas(item.product_amount) }}
                    </td>
                    <td scope="col">
                      {{ Commas(item.weight*item.product_amount) }}
                    </td>
                    <td scope="col">
                      {{ Commas(item.width) }}
                    </td>
                    <td scope="col">
                      {{ Commas(item.height) }}
                    </td>
                    <td scope="col">
                      {{ Commas(item.long) }}
                    </td>
                    <td scope="col">
                      {{ CommasCue(item.charge_cue*item.product_amount) }}
                    </td>
                    <td scope="col">
                      {{ item.product_type ? item.product_type.pro_initial: '-' }}
                    </td>
                    <td scope="col">
                      {{ item.come_thaiwarehouse ? item.come_thaiwarehouse: '-' }}
                    </td>
                    <td scope="col">
                      {{ Commas(item.product_inspection_fee) }}
                    </td>
                    <td scope="col">
                      {{ Commas(item.re_wrap_fee) }}
                    </td>
                    <td scope="col">
                      {{ Commas(item.shipping_fee) }}
                    </td>
                    <td scope="col">
                      {{ Commas(item.other) }}
                    </td>
                    <td scope="col">
                      {{ Commas(item.packaging_fee) }}
                    </td>
                    <td scope="col">
                      <span v-if="item.discount && item.charge_per_kilo === item.charge_per_save"> {{
                        Commas(item.charge_per_kilo) }}<span class="text-danger">-{{ Commas(item.discount) }}% {{
                        Commas(item.charge_per_save*(100-item.discount)/ 100) }}</span> </span>
                      <span v-else>{{ item.charge_per_kilo === item.charge_per_save ? Commas(item.charge_per_save): '-'
                      }}</span>
                    </td>
                    <td scope="col">
                      <span v-if="item.discount && item.charge_per_cue === item.charge_per_save"> {{
                        Commas(item.charge_per_cue)
                      }}<span class="text-danger">-{{ Commas(item.discount) }}% {{
                        Commas(item.charge_per_save*(100-item.discount)/
                          100) }}</span> </span>
                      <span v-else>{{ item.charge_per_cue === item.charge_per_save ? Commas(item.charge_per_save): '-'
                      }}</span>
                    </td>
                  </tr>
                </tbody>
                <tfoot class="text-center">
                  <tr>
                    <th
                      class="text-center"
                      colspan="5"
                      style="background: #fff; color: black;"
                    >
                      <b>{{ $t('total') }}</b>
                    </th>
                    <th
                      class="text-center"
                      scope="col"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ Commas(countproducts()) }}</b>
                    </th>
                    <th
                      scope="col"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ Commas(countweight()) }}</b>
                    </th>
                    <th
                      class="text-center"
                      scope="col"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ Commas(countwidth()) }}</b>
                    </th>
                    <th
                      class="text-center"
                      scope="col"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ Commas(countheight()) }}</b>
                    </th>
                    <th
                      class="text-center"
                      scope="col"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ Commas(countproductLong()) }}</b>
                    </th>
                    <th
                      class="text-center"
                      scope="col"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ CommasCue(countquantity()) }}</b>
                    </th>
                    <th
                      scope="col"
                      colspan="7"
                      style="background: #fff"
                    />

                    <th
                      scope="col"
                      class="text-center"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ Commas(conutkilo()) }}</b>
                    </th>
                    <th
                      scope="col"
                      class="text-center"
                      style="background: #ffc1c18a; color: black;"
                    >
                      <b>{{ Commas(conutcue()) }}</b>
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div class="d-flex align-items-center justify-content-start p-2">
              <!-- <span class="czx ctx-yellow">
                {{ $t('checkGoods') }}
              </span> -->

              <span class="czx ctx-green">
                {{ $t('checkOrder') }}
              </span>

              <span class="czx ctx-orange">
                {{ $t('woodPacking') }}
              </span>

              <span class="czx ctx-red">
                {{ $t('noPrice') }}
              </span>

              <!-- <span class="czx ctx-purple">
                claim
              </span> -->
            </div>
          </div>
        </b-col>
      </b-row>
    </b-overlay>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  // BFormInput,
  VBToggle,
  BFormCheckbox,
  // BInputGroupAppend,
  // BInputGroup,
  BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BRow,
    BCol,
    // BFormInput,
    BButton,
    BFormCheckbox,
    vSelect,
    // BInputGroupAppend,
    // BInputGroup,
    BOverlay,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      type: 'A',
      itemsSelect: [
        { value: 'A' },
        { value: 'B' },
        { value: 'C' },
        { value: 'D' },
        { value: 'D1' },
        { value: 'S' },
      ],
      isActive: true,
      selectAll: false,
      selectItem: { key: 'checkbox', value: false },
      x: [],
      option: [],
      selectCustomer: null,
      getoption: [],
      items: [],
      main_items: [],
      ProductTypeitem: [],
      numberlength: 0,
      payment: 0,
      arritems: [],
      checkitems: 0,
      itemspvc: [],
      idistrict: [],
      iSubdistrict: [],
      izipcode: [],
      showOver: false,
      search_val: '',
    }
  },
  computed: {
  },
  watch: {
    selectItem: {
      handler() {
        this.updateCheckAllState()
      },
      deep: true,
    },
  },
  mounted() {
    this.getUserall()
    this.getPostNumber()
    this.sub_role = []
  },
  methods: {
    FilterItem(val) {
      const regex = new RegExp(val)
      this.items = this.main_items.filter(item => regex.test(item.postnumber))
    },
    toggleAll() {
      for (let i = 0; i < this.items.length; i + 1) {
        this.selectItem[i] = this.selectAll
      }
    },
    getUserall() {
      try {
        this.$http.get('/Paymentuser/getcustomer')
          .then(response => {
            this.option = response.data.data
            this.payment = 0
            this.numberlength = 0
            // console.log(this.option)
          }).catch(err => {
            console.log(err)
          })
      } catch (e) {
        console.log(e)
      }
    },
    getPostNumber() {
      this.showOver = true
      try {
        this.$http.get('/postnumber/noname')
          .then(response => {
            this.showOver = false
            const Data = response.data.data
            const res = Data.map(item => {
              // eslint-disable-next-line no-param-reassign
              item.select = false
              return item
            })
            this.main_items = [...res]
            this.items = [...res]
          }).catch(err => {
            console.log(err)
            this.showOver = false
          })
      } catch (e) {
        console.log(e)
      }
    },
    async updatePost() {
      this.showOver = true
      const postnumber = this.items.filter(item => item.select === true)
      // eslint-disable-next-line no-underscore-dangle
      const postnumMap = postnumber.map(item => item._id)
      console.debug(postnumMap)
      console.debug(this.selectCustomer)
      try {
        const obj = {
          postnumber_id: postnumMap,
          user: this.selectCustomer,
        }
        this.$http.post('/postnumber/noname/update', obj)
          .then(response => {
            this.showOver = false
            this.getPostNumber()
            this.arritems = []
          })
      } catch (e) {
        console.log(e)
        this.showOver = false
      }
    },
    CommasCue(x) {
      if (!x) {
        return '0'
      }
      const parts = x.toString().split('.')
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')

      if (parts[1]) {
        parts[1] = parseFloat(`0.${parts[1]}`).toFixed(4).split('.')[1]
      } else {
        parts.push('0000')
      }
      return parts.join('.')
    },
    EditType(item) {
      // eslint-disable-next-line no-underscore-dangle
      this.$router.push({ name: 'admin-tracking-edit', params: { id: item._id } })
    },
    Alert(status, title, mes) {
      this.$swal({
        icon: `${status ? 'warning' : 'error'}`,
        title: `<h3 style="color: #141414">${title}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-warning',
        },
      })
    },
    countproducts() {
      return this.items.reduce((total, item) => total + item.product_amount, 0)
    },
    countweight() {
      return this.items.reduce((total, item) => total + (item.weight * item.product_amount), 0)
    },
    countwidth() {
      return this.items.reduce((total, item) => total + (item.width), 0)
    },
    countheight() {
      return this.items.reduce((total, item) => total + (item.height), 0)
    },
    countproductLong() {
      return this.items.reduce((total, item) => total + (item.long), 0)
    },
    countquantity() {
      return this.items.reduce((total, item) => total + (item.charge_cue * item.product_amount), 0)
    },
    conutkilo() {
      const arrkilo = []
      this.items.forEach(ele => {
        if (ele.charge_per_kilo === ele.charge_per_save) {
          arrkilo.push(ele.charge_per_kilo)
        }
      })
      return arrkilo.reduce((total, item) => total + item, 0)
    },
    conutcue() {
      const arrcue = []
      this.items.forEach(ele => {
        if (ele.charge_per_cue === ele.charge_per_save) {
          arrcue.push(ele.charge_per_cue)
        }
      })
      return arrcue.reduce((total, item) => total + item, 0)
    },
    checkboxselect(check, item) {
      if (check) {
        if (item.discount) {
          this.payment += (item.charge_per_save * (100 - item.discount)) / 100
          this.numberlength += 1
          this.arritems.push(item)
          this.checkitems = 2
        } else {
          this.payment += item.charge_per_save
          this.numberlength += 1
          this.arritems.push(item)
          this.checkitems = 2
        }
        // eslint-disable-next-line no-underscore-dangle
      } else {
        const index = this.arritems.indexOf(item)
        if (index !== -1) {
          this.arritems.splice(index, 1)
          if (item.discount) {
            this.payment -= (item.charge_per_save * (100 - item.discount)) / 100
            this.numberlength -= 1
          } else {
            this.payment -= item.charge_per_save
            this.numberlength -= 1
          }
        }
        // this.numberlength -= 1

        // this.payment -= item.charge_per_save
      }
      if (!this.arritems.length) {
        this.checkitems = 1
      }
    },
    isIndeterminate() {
      this.items.forEach(item => {
        if (this.selectAll) {
          // eslint-disable-next-line no-param-reassign
          item.select = true
        } else {
          // eslint-disable-next-line no-param-reassign
          item.select = false
        }
      })
      this.items = [...this.items]
    },
    checkedcolor(item) {
      if (item.packbox && item.checkproduct) {
        return 'yellow'
      } if (item.checkproduct) {
        return 'green'
      } if (item.packbox) {
        return 'orange'
      } if (item.charge_per_save === 0) {
        return 'red'
      }
      return '#ffffff'
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

      <style lang="scss">
      @import "@core/scss/vue/libs/vue-select.scss";
      </style>
